import React from "react"
import { TbTemplate } from "react-icons/tb"
import logo from "../images/favicon.png"
import { Link, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { LOGOUT_REQUEST } from "../redux/auth/authAction.jsx"
import { MdOutlineNotificationsActive, MdOutlineDashboard } from "react-icons/md"
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2"
import { FaRegUser } from "react-icons/fa6"
import { LuLogOut } from "react-icons/lu"

const Sidebar = () => {
  const locationname = useLocation()
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch({ type: LOGOUT_REQUEST })
  }

  return (
    <>
      <div className="bg-white-100 text-black-100 h-screen w-16 sm:w-auto  flex flex-col justify-between items-center px-3">
        <div className="flex flex-col items-center w-full">
          <img
            src={logo}
            alt="logo"
            className="py-6 sm:py-12 px-1 "
            width="80"
            height="80"
          />
          <div className="text-xl sm:text-2xl text-grey_font-100 flex flex-col items-center gap-6 sm:gap-10">
            <Link
              to="/dashboard"
              title="Dashboard"
              className="flex flex-col gap-1 items-center"
            >
              <button
                className={
                  locationname.pathname === "dashboard"
                    ? "bg-black-400 text-primary-100 rounded-md p-2"
                    : "bg-transparent"
                }
                aria-label="Dashboard"
                type="button"
              >
                <MdOutlineDashboard />
              </button>
              <div
                className={`${
                  location.pathname === "/dashboard"
                    ? "text-primary-100"
                    : "bg-transparent"
                } text-sm lg:block hidden`}
              >
                Dashboard
              </div>
            </Link>
            <Link
              to="/template"
              title="Template"
              className="flex flex-col gap-1 items-center"
            >
              <button
                className={
                  locationname.pathname === "/template"
                    ? "bg-black-400 text-primary-100 rounded-md p-2"
                    : "bg-transparent"
                }
                aria-label="Template"
                type="button"
              >
                <TbTemplate />
              </button>
              <div
                className={`${
                  location.pathname === "/template"
                    ? "text-primary-100"
                    : "bg-transparent"
                } text-sm lg:block hidden`}
              >
                Template
              </div>
            </Link>
            <Link
              to="/chats"
              title="Chats"
              className="flex flex-col gap-1 items-center "
            >
              <button
                className={`${
                  locationname.pathname === "/chats"
                    ? "bg-black-400 text-primary-100 rounded-md p-2"
                    : "bg-transparent "
                } relative  
                `}
                aria-label="Template"
                type="button"
              >
                <HiOutlineChatBubbleLeftRight />
                <span className="sr-only">Notifications</span>
                <div className="absolute inline-flex items-center justify-center w-5 h-5 text-[0.5rem] font-semibold bg-orange-500 text-white-100  rounded-full -top-2 -end-2">
                  100
                </div>
              </button>

              <div
                className={`${
                  location.pathname === "/chats"
                    ? "text-primary-100"
                    : "bg-transparent"
                } text-sm lg:block hidden`}
              >
                Chats
              </div>
            </Link>
          </div>
        </div>
        <div className="pb-10 flex flex-col items-center gap-5 text-grey_font-100 ">
          <div className="relative border p-2 rounded-lg text-primary-100 shadow-sm">
            <MdOutlineNotificationsActive size={25} />
            <span className="sr-only">Notifications</span>
            <div className="absolute inline-flex items-center justify-center p-1 text-[0.5rem] font-semibold text-white bg-orange-500 text-white-100  rounded-full top-0 end-0 dark:border-gray-900">
              100
            </div>
          </div>
          <div className="border p-3 rounded-lg text-primary-100 shadow-sm">
            <FaRegUser size={20} />
          </div>
          <button
            className="border p-3 rounded-lg text-primary-100 shadow-sm"
            onClick={handleLogout}
          >
            <LuLogOut size={20} />
          </button>
        </div>
      </div>
    </>
  )
}

export default Sidebar
