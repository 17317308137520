import React, { Suspense } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import Layout from "../component/staticHome/layout.jsx"
import Home from "../component/staticHome/Home.jsx"
import Login from "../auth/Login.jsx"
import Register from "../auth/Register.jsx"
import DashboardIndex from "../component/Dashboard/DashboardIndex.jsx"
import Main from "../Main.jsx"
import TemplateIndex from "../component/template/TemplateIndex.jsx"
import SenderReportIndex from "../component/senderreport/SenderReportIndex.jsx"
import ContactUs from "../component/staticHome/ContactUs.jsx"
import Summary from "../component/senderreport/Summary.jsx"
import ModifyTempIndex from "../component/modifyTemplate/ModifyTempIndex.jsx"
import ChatIndex from "../component/chats/ChatIndex.jsx"
import Loader from "../component/global/Loader.jsx"
import PrivateRoute from "./PrivateRouter.jsx"

const AppRouter = () => {
  const isAuthenticated = localStorage.getItem("access_token")
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Layout />}
          >
            {" "}
            <Route index element={<Home />} />{" "}
            <Route path="/Contact" element={<ContactUs />} />
          </Route>
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
          />
          <Route path="/register" element={<Register />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardIndex />
              </PrivateRoute>
            }
          />
          <Route
            path="/template"
            element={
              <PrivateRoute>
                <Main component={TemplateIndex} />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports/:id"
            element={
              <PrivateRoute>
                <Main component={SenderReportIndex} />
              </PrivateRoute>
            }
          />
          <Route
            path="/summary/:params/:id"
            element={
              <PrivateRoute>
                <Main component={Summary} />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/reports/:id"
            element={
              <PrivateRoute>
                <Main component={SenderReportIndex} />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/modifytemplate"
            element={
              <PrivateRoute>
                <Main component={ModifyTempIndex} />
              </PrivateRoute>
            }
          />
          <Route
            path="/chats"
            element={
              <PrivateRoute>
                <Main component={ChatIndex} />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default AppRouter
