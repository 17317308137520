import React, { useEffect, useState } from "react"
import TypingField from "./TypingField.jsx"
import ChatMessages from "./ChatMessages.jsx"
import { useUIContext } from "../../context/BasicProvider.jsx"
import { SOCKET_URL } from "../../config/Api.jsx"

const Chat = () => {
  const { chatData } = useUIContext()
  const [socket, setSocket] = useState(null)
  useEffect(() => {
    // Create WebSocket connection
    const newSocket = new WebSocket(SOCKET_URL + "/919265790630/")
    setSocket(newSocket)
    // Event listener for incoming messages
    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data)
      console.log("data", data)

      // setMessages((prevMessages) => [...prevMessages, data])
    }

    // WebSocket connection open
    newSocket.onopen = () => {
      console.log("Connected to WebSocket server")
    }

    // WebSocket connection close
    newSocket.onclose = () => {
      console.log("Disconnected from WebSocket server")
    }

    // Clean up WebSocket connection on component unmount
    return () => {
      if (newSocket) {
        newSocket.close()
      }
    }
  }, [])

  const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = JSON.stringify({
        message: message,
        username: "admin",
        phone_no: "919265790630",
        business_Phone_id: "289313284265891",
      })
      socket.send(data)
    } else {
      console.error("WebSocket is not open")
    }
  }
  return (
    <div className="w-[75%] h-[100vh] flex flex-col">
      <div className="sticky top-0 flex border-b h-[4.5rem] p-3 gap-2 items-center z-10">
        <img
          src={chatData.img}
          alt="profile"
          className="w-[3rem] h-[3rem] rounded-full"
        />
        <div className="font-bold">{chatData.number}</div>
      </div>

      <ChatMessages chatData={chatData} />

      <TypingField sendMessage={sendMessage} />
    </div>
  )
}

export default Chat
