import React from "react"
import GoToTop from "./GoToTop.jsx"
import Hero from "./Hero.jsx"
import Features from "./Features.jsx"
import Channel from "./Channel.jsx"
import Faq from "./Faq.jsx"

const Home = () => {
  return (
    <>
      <GoToTop />
      <Hero />
      <Features />
      <Faq />
      <Channel />
    </>
  )
}

export default Home
