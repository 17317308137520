// ChatList.jsx
import { PiChatsLight } from "react-icons/pi"
import { FiSearch } from "react-icons/fi"
import { chatDetail } from "../../data/Mockdata.jsx"
import { useUIContext } from "../../context/BasicProvider.jsx"
import { TitleHeader } from "../global/TitleHeader.jsx"
// const SOCKET_URL = "wss://c4cb-150-107-232-246.ngrok-free.app/ws/chat/919265790630/"
const ChatList = () => {
  const { chatUser, setChatUser, setChatData } = useUIContext()

  const handleUserClick = (userId) => {
    setChatUser(userId)

    const selectedChat = chatDetail.find((chat) => chat.id === userId)
    if (selectedChat) {
      setChatData(selectedChat)
    }
  }

  return (
    <div className="w-[25%] h-[100vh] border-r flex flex-col">
      <div className="sticky top-0 bg-white z-10 ">
        <div className="px-4 sm:px-8 py-4 sm:pt-5 flex justify-between items-center gap-3 h-[4.5rem] border-b">
          <div className="flex justify-between items-center flex-wrap gap-3">
            <TitleHeader title="Chats" />
          </div>
          <button
            type="button"
            className="relative inline-flex items-center p-3 text-sm font-medium text-center text-primary-100 "
          >
            <PiChatsLight size={30} />
            <span className="sr-only">Notifications</span>
            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-orange-500 text-white-100 border-2 border-white rounded-full top-0 end-0 dark:border-gray-900">
              20
            </div>
          </button>
        </div>

        <div className="py-4 px-3 bg-white">
          <div className="relative border rounded-full bg-gray-100">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FiSearch className="text-gray-500" />
            </span>
            <input
              type="text"
              placeholder="Search or start new chat"
              className="w-full bg-transparent pl-10 pr-4 py-2 rounded-full focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
        </div>
      </div>

      <div className="flex-grow overflow-y-auto">
        {chatDetail &&
          chatDetail.map((chat, idx) => (
            <button
              key={idx}
              onClick={() => handleUserClick(chat.id)}
              className={`flex justify-between p-3 border-b hover:bg-bg_color-200 cursor-pointer w-full ${
                chatUser === chat.id ? "bg-bg_color-200" : "bg-transparent"
              }`}
            >
              <div className="flex gap-2">
                <img
                  src={chat.img}
                  alt="Profile"
                  className="w-[3rem] h-[3rem] rounded-full"
                />
                <div className="flex flex-col gap-1">
                  <div className="font-bold">{chat.number}</div>
                  <div className="font-light text-grey_font-500 text-sm truncate">
                    {chat.msg.length > 15
                      ? chat.msg.substring(0, 15) + "..."
                      : chat.msg}
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-1">
                <div>{chat.time}</div>
                <div
                  className={`bg-primary-100 text-white-100 rounded-full w-6 h-6 flex items-center justify-center ${
                    chat.notify === "0" ? "hidden" : ""
                  }`}
                >
                  {chat.notify}
                </div>
              </div>
            </button>
          ))}
      </div>
    </div>
  )
}

export default ChatList
