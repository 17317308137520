import { Navigate, useLocation } from "react-router-dom"

const PrivateRoute = ({ children }) => {
  const location = useLocation()
  let isAuth = localStorage.getItem("access_token")

  return isAuth && isAuth !== undefined ? (
    <>{children}</>
  ) : (
    <Navigate
      replace={true}
      to="/login"
      state={{ from: `${location.pathname}${location.search}` }}
    />
  )
}

export default PrivateRoute
